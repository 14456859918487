<template>
  <div>
    <b-overlay :show="loading">
      <b-card>
        <h4 class="card-title">Reportes Mutual</h4>
        <div v-if="visibleReport">
          <div class="demo-inline-spacing" v-if="showColumn">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="columnsDefault"
            >
              Columnas por Defecto
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="checkAllColumns"
            >
              <feather-icon
                  icon="check"
                  class="mr-50"
              />
              <span class="align-middle">Todas las Columnas</span>
            </b-button>
          </div>
          <div class="demo-inline-spacing ml-1" v-if="showColumn">

            <b-form-checkbox
                v-for="column in columns"
                v-model="column.display"
                name="flavour-4a"
                inline
            >
              {{ column.label }}
            </b-form-checkbox>
          </div>
          <b-row class="pr-1 pl-1">
            <b-col sm="12" md="6"  class="my-1 text-right">
              <div class="d-flex align-items-center mb-1 mb-md-0">
                <label>Mostrar</label>
                <b-form-select
                    @change="getList"
                    id="perPageSelect"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                    class="mx-50 col-md-2"
                ></b-form-select>
                <label>filas por página</label>
              </div>
            </b-col>
            <b-col sm="4" md="6" class="my-1">
              <div class="d-flex justify-content-end">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="showColumns"
                >
                  <feather-icon v-if="!showColumn"
                                icon="EyeIcon"
                                class="mr-50"
                  />
                  <feather-icon v-if="showColumn"
                                icon="EyeOffIcon"
                                class="mr-50"
                  />
                  <span class="align-middle">{{showColumnText}}</span>

                </b-button>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                    class="ml-1"
                    @click="exportar"
                >
                  <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Exportar</span>

                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-col cols="12" v-if="showTable">
            <b-table
                striped
                hover
                small
                :per-page="perPage"
                :items="listado"
                :fields="filteredHeaders"
                responsive
                class="mb-0"
                id="my-table"
                ref="table"
                show-empty
            >
              <template #cell(id_tbl_course_ldm)="data">
                {{ data.item.id_tbl_course_ldm  ? data.item.id_tbl_course_ldm : '-'}}
              </template>
              <template #cell(num_ficha)="data">
                {{ data.item.num_ficha  ? data.item.num_ficha : '-'}}
              </template>
              <template #cell(num_ficha)="data">
                {{ data.item.num_ficha  ? data.item.num_ficha : '-'}}
              </template>
              <template #cell(num_orden_compra)="data">
                {{ data.item.num_orden_compra  ? data.item.num_orden_compra : '-'}}
              </template>
              <template #cell(nombre)="data">
                {{ data.item.nombre  ? data.item.nombre : '-'}}
              </template>
              <template #cell(apellidos)="data">
                {{ data.item.apellidos  ? data.item.apellidos : '-'}}
              </template>
              <template #cell(rut)="data">
                {{ data.item.rut  ? data.item.rut : '-'}}
              </template>
              <template #cell(correo1)="data">
                {{ data.item.correo1  ? data.item.correo1 : '-'}}
              </template>
              <template #cell(correo1)="data">
                {{ data.item.correo2  ? data.item.correo2 : '-'}}
              </template>
              <template #cell(correo_personal)="data">
                {{ data.item.correo_personal  ? data.item.correo_personal : '-'}}
              </template>
              <template #cell(correo_institucional)="data">
                {{ data.item.correo_institucional  ? data.item.correo_institucional : '-'}}
              </template>
              <template #cell(fono1)="data">
                {{ data.item.fono1  ? data.item.fono1 : '-'}}
              </template>
              <template #cell(fono2)="data">
                {{ data.item.fono2  ? data.item.fono2 : '-'}}
              </template>
              <template #cell(telefono_curso)="data">
                {{ data.item.telefono_curso  ? data.item.telefono_curso : '-'}}
              </template>
              <template #cell(celular_curso)="data">
                {{ data.item.celular_curso  ? data.item.celular_curso : '-'}}
              </template>
              <template #cell(telefono_contacto)="data">
                {{ data.item.telefono_contacto == 1 ? "Si" : "No" }}
              </template>
              <template #cell(aplicacion)="data">
                {{ data.item.aplicacion  ? data.item.aplicacion : '-'}}
              </template>
              <template #cell(nombre_corto)="data">
                {{ data.item.nombre_corto  ? data.item.nombre_corto : '-'}}
              </template>
              <template #cell(fecha_inicio)="data">
                <b-badge variant="success">
                  {{ data.item.fecha_inicio  ? data.item.fecha_inicio : '-'}}
                </b-badge>
              </template>
              <template #cell(fecha_cierre)="data">
                <b-badge variant="success">
                  {{ data.item.fecha_cierre  ? data.item.fecha_cierre : '-'}}
                </b-badge>
              </template>
              <template #cell(modality)="data">
                {{ data.item.modality  ? data.item.modality : '-'}}
              </template>
              <template #cell(conexion_sence)="data">
                {{ data.item.conexion_sence  ? data.item.conexion_sence : '-'}}
              </template>
              <template #cell(tutor_asignado)="data">
                {{ data.item.tutor_asignado  ? data.item.tutor_asignado : '-'}}
              </template>
              <template #cell(razon_social_empresa)="data">
                {{ data.item.razon_social_empresa  ? data.item.razon_social_empresa : '-'}}
              </template>
              <template #cell(nombre_holding)="data">
                {{ data.item.nombre_holding  ? data.item.nombre_holding : '-'}}
              </template>
              <template #cell(sucursal)="data">
                {{ data.item.sucursal  ? data.item.sucursal : '-'}}
              </template>
              <template #cell(descripcion_centro_costo)="data">
                {{ data.item.descripcion_centro_costo  ? data.item.descripcion_centro_costo : '-'}}
              </template>
              <template #cell(porciento_avance)="data">
                <b-badge class="center" :variant="porciento_avance(data.item.porciento_avance)">{{ data.item.porciento_avance  ? data.item.porciento_avance : '-'}}</b-badge>
              </template>
              <template #cell(finalizado)="data">
                {{ data.item.finalizado  ? data.item.finalizado : '-'}}
              </template>
              <template #cell(porciento_evaluaciones_realizadas)="data">
                {{ data.item.porciento_evaluaciones_realizadas  ? data.item.porciento_evaluaciones_realizadas : '-'}}
              </template>
              <template #cell(evaluacion_final_fecha)="data">
                <b-badge v-if="data.item.evaluacion_final_fecha" variant="success">
                  {{ data.item.evaluacion_final_fecha}}
                </b-badge>
                <div v-if="!data.item.evaluacion_final_fecha">-</div>
              </template>
              <template #cell(evaluacion_final_fecha)="data">
                <b-badge variant="success">
                  {{ data.item.evaluacion_final_fecha  ? data.item.evaluacion_final_fecha : '-'}}
                </b-badge>
              </template>
              <template #cell(ultimo_acceso)="data">
                <b-badge v-if="data.item.ultimo_acceso" variant="success">
                  {{ data.item.ultimo_acceso}}
                </b-badge>
                <div v-if="!data.item.ultimo_acceso">-</div>
              </template>
              <template #cell(cat2_accion_realizada)="data">
                {{ data.item.cat2_accion_realizada  ? data.item.cat2_accion_realizada : '-'}}
              </template>
              <template #cell(cat4_resultado)="data">
                {{ data.item.cat4_resultado  ? data.item.cat4_resultado : '-'}}
              </template>
              <template #cell(fecha_ultimo_seguimiento)="data">
                {{ data.item.fecha_ultimo_seguimiento  ? data.item.fecha_ultimo_seguimiento : '-'}}
              </template>
              <template #cell(cantidad_seguimiento)="data">
                {{ data.item.cantidad_seguimiento  ? data.item.cantidad_seguimiento : '-'}}
              </template>
              <template #cell(registro_sence)="data">
                {{ data.item.registro_sence  ? data.item.registro_sence : '-'}}
              </template>
              <template #cell(estado_dj)="data">
                {{ data.item.estado_dj  ? data.item.estado_dj : '-'}}
              </template>
              <template #cell(registro_historico)="data">
                {{ data.item.registro_historico  ? data.item.registro_historico : '-'}}
              </template>
              <template #cell(detalle_seguimiento)="data">
                <div class="text-center" v-if="data.item.detalle_seguimiento">
                  <b-button
                      id="tooltip-detalle"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="flat-primary"
                      class="btn-icon"
                      @click="showDetalleSeguimiento(data.item.detalle_seguimiento)"
                  >
                    <feather-icon icon="EyeIcon"
                    />
                  </b-button>
                  <b-tooltip target="tooltip-detalle" triggers="hover" variant="primary">
                    Ver detalle seguimiento
                  </b-tooltip>
                </div>
                <div v-else>-</div>
              </template>
              <template #cell(data_auxiliar)="data">
                <div class="text-center" v-if="data.item.data_auxiliar">
                  <b-button
                      id="tooltip-adicionales"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="flat-primary"
                      class="btn-icon"
                      @click="showDatosAdicionales(data.item.data_auxiliar)"
                  >
                    <feather-icon icon="EyeIcon"
                    />
                  </b-button>
                  <b-tooltip target="tooltip-detalle" triggers="hover" variant="primary">
                    Ver datos adicionales
                  </b-tooltip>
                </div>
                <div v-else>-</div>
              </template>
              <template #empty="scope">
                <div class="text-center">No existen resultados</div>
              </template>
            </b-table>

          </b-col>

          <b-row class="pl-1">
            <b-col  cols="8" class="mt-3">
              <div v-if="totalRows > 0">
                <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
              </div>
            </b-col>

            <b-col cols="4">
              <b-pagination
                  @change="onPageChange"
                  v-model="page"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  class="mt-3 mr-1"
                  aria-controls="my-table"
              />
            </b-col>

          </b-row>
        </div>

      </b-card>
    </b-overlay>
    <modal-ds ref="modal_dtlle" :dataValue="dataDetalle"></modal-ds>
    <modal-da ref="modal_adicionales" :dataValue="dataAdicionales" :actividades="actividades"></modal-da>
    <b-modal ref="modalExport"
             title="Información"
             ok-only
             ok-title="Cerrar">
      <p class="my-4"><b>Procesando el reporte</b>! Recibirá un correo con el enlace para descargar el informe</p>
    </b-modal>
  </div>
</template>

<script>
import FiltrosReportes from '@/components/FiltrosReportes.vue'
import {
  BTable,
  BProgress,
  BBadge,
  BPagination,
  BRow,
  BCol,
  BFormSelect,
  BTfoot,
  BTr,
  BTh,
  BFormCheckbox,
  BButton,
  BTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ModalDetalleSeguimiento from "@/components/ModalDetalleSeguimiento";
import ModalDatosAdicionales from "@/components/ModalDatosAdicionales";
export default {
  name: "ReportesMutualPractico",
  components: {
    BTable,
    BProgress,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BFormSelect,
    BTfoot,
    BTr,
    BTh,
    BFormCheckbox,
    BButton,
    BTooltip,
    'filtros-reportes': FiltrosReportes,
    'modal-ds': ModalDetalleSeguimiento,
    'modal-da': ModalDatosAdicionales,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //loading: false,
      showTable: true,
      visibleReport: false,
      perPage: 50,
      reportType: 1,
      currentPageV: 1,
      page: 1,
      list:[],
      columnsData:[],
      dataDetalle:[],
      dataAdicionales:[],
      actividades:[],
      isVisible:true,
      filter: {
        type:3,
        tab:3,

      },
      sortField: 'nombre',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      sortDesc: false,
      showColumn: false,
      showColumnText: 'Mostrar Columnas',
      pageOptions: [50, 100, 500, 1000],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
    }
  },
  mounted() {
    this.getColumns();
    this.getList();
  },
  methods: {
    buscar(filter) {
      this.filter = filter;
      this.isVisible = false
      const h = this.$createElement


      this.getList();
      this.getColumns();


    },
    onPageChange(page) {
      this.page = page;
      //this.currentPageV = page
      this.getList();
    },
    getList() {
      this.visibleReport = true;
      let filterData = {
        'type': this.filter.type,
        'tab': this.filter.tab,
        'page': this.page,
        'perPage': this.perPage,
      }
      this.$store.dispatch('reportesMutualPractico/getList', filterData)
    },
    getColumns() {
      let filterData = {
        'type': this.filter.type,
        'tab': this.filter.tab,
        'page': this.page,
        'perPage': this.perPage,
      }
      this.$store.dispatch('reportesMutualPractico/getColumns', filterData)
    },
    showColumns() {
      this.showColumn = this.showColumn == true ? false : true;
      this.showColumnText = this.showColumn == true ? 'Ocultar Columnas' : 'Mostrar Columnas';
    },
    columnsDefault() {
      for (let key in this.columnsData) {
        this.columnsData[key].display = this.columnsData[key].default;
      }
    },
    checkAllColumns() {
      for (let key in this.columnsData) {
        this.columnsData[key].display = true;
      }
    },
    porciento_avance(value) {
      const number = parseFloat(value)
      if (number < 50) {
        return 'danger'
      } else if (number >= 50 && number < 100) {
        return 'warning'
      } else if (number >= 100) {
        return 'success'
      }
    },
    exportar() {
      if (this.listado.length == 0) {
        this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
          title: `ALERTA!!`,
          variant: 'warning',
          solid: true,
        })
      } else {
        this.getExportData();
      }
    },
    getExportData() {
      let fieldsV = [];
      Object.entries(this.columnsData).forEach(entry => {
        const [key, value] = entry;
        if (value.display) {
          fieldsV.push(value.key);
        }
      });
      let filterData = {
        'type': this.filter.type,
        'tab': this.filter.tab,
        'page': this.page,
        'perPage': this.perPage,
      }
      if(this.totalRows > 5000)
      {
        this.$refs.modalExport.show();
        this.$store.dispatch('reportesMutualPractico/exportAllData', filterData)
      }else {
        this.$store.dispatch('reportesMutualPractico/exportData', filterData)
      }
    },
    showDetalleSeguimiento(data){
      this.dataDetalle = Object.values(data)
      this.$refs.modal_dtlle.$children[0].show();
    },
    showDatosAdicionales(data){
      //this.dataAdicionales = JSON.parse(data)
      this.dataAdicionales = data
      if(this.dataAdicionales.actividades){
        this.actividades = this.dataAdicionales.actividades
      }else {
        this.actividades[0]= this.dataAdicionales
      }

      this.$refs.modal_adicionales.$children[0].show();
    },

  },

  computed: {
    totalRows() {
      return this.$store.state.reportesMutualPractico.rowsTotal;
    },
    to() {
      return this.$store.state.reportesMutualPractico.to;
    },
    from() {
      return this.$store.state.reportesMutualPractico.from;
    },
    listado() {
      return this.$store.state.reportesMutualPractico.list
    },
    currentPage() {
      return this.$store.state.reportesMutualPractico.currentPage
    },
    loading() {
      return  this.$store.state.reportesMutualPractico.loading;
    },
    columns() {
      const data = this.$store.state.reportesMutualPractico.columns;
      this.columnsData = []
      for (const key in data) {
        const column = {
          key: data[key].field,
          label: data[key].title,
          display: data[key].visible,
          default: data[key].default,
        };
        this.columnsData.push(column)
      }
      return this.columnsData
      //return  this.$store.state.reportesMutualIntro.columns;
    },
    filteredHeaders() {
      return this.columns.filter(h => h.display === true)
    },

  },

  watch: {

  }
}

</script>

<style scoped>

</style>
