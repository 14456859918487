<template>
  <b-card>
    <b-tabs>
      <b-tab
          active
          title="SAP INTRO"
      >
        <reportes-mutual-intro />
      </b-tab>
      <b-tab
          title="SAP ROL"
      >
        <reportes-mutual-rol />
      </b-tab>
      <b-tab
          title="SAP PRACTICO"
      >
        <reportes-mutual-practico />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>

import ReportesMutualPractico from '@/views/ReportesMutual/ReportesMutualPractico.vue'
import ReportesMutualRol from '@/views/ReportesMutual/ReportesMutualRol'
import ReportesMutualIntro from '@/views/ReportesMutual/ReportesMutualIntro'

export default {
  name: 'TabReportesMutual',
  components: {
    'reportes-mutual-practico': ReportesMutualPractico,
    'reportes-mutual-rol': ReportesMutualRol,
    'reportes-mutual-intro': ReportesMutualIntro,
  },
}
</script>

<style scoped>

</style>